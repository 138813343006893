<template>
  <div class="h-screen" :class="isValidToken ? 'mt-0 p-2' : 'mt-12 p-2'">
    <div class=" bg-white rounded-xl" v-if="!isValidToken">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
          <div class=" relative flex items-center justify-between">
            <div>
              Quotation ({{totalQuoteCount}})
            </div>
          </div>
        </div>
      </div>
      <div class="pt-1">
        <div class="mt-4 p-2 pb-0">
          <div class="flex flex-wrap justify-between items-start ">
            <div class="w-64 mb-4" :class="mobileView ? 'w-64' : 'w-72'">
              <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Quotation...`"/>
            </div>
            <div>
              <span @click="filterShow = true" :class="` text-3xl ${filterApply ? 'text-success' : 'text-primary'}`"><i class="fa-solid fa-filter"></i></span>
            </div>
          </div>
        </div>
      </div>
      <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="!mobileView">
        <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
          <div class="quote_name_col w-32 uppercase  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''" >
            <span class="cursor-pointer" @click="sortByColumn(0)">{{titleObj[0].title}}</span>
             <div v-if="titleObj[0].icon !== ''">
                <descIconSvg v-if="titleObj[0].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
              </div>
          </div>
          <div class="quote_name_col w-56 uppercase  flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''" >
            <span class="cursor-pointer" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
             <div v-if="titleObj[1].icon !== ''">
                <descIconSvg v-if="titleObj[1].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
              </div>
          </div>
          <div class="quote_name_col w-48 uppercase  flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''" >
            <span class="cursor-pointer" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
             <div v-if="titleObj[2].icon !== ''">
                <descIconSvg v-if="titleObj[2].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
              </div>
          </div>
          <div class="quote_name_col w-56 uppercase  flex items-center" :class="titleObj[3].icon !== '' ? 'text-primary' : ''" >
            <span class="cursor-pointer" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
             <div v-if="titleObj[3].icon !== ''">
                <descIconSvg v-if="titleObj[3].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
              </div>
          </div>
          <div class="quote_name_col w-32 uppercase  flex items-center" :class="titleObj[4].icon !== '' ? 'text-primary' : ''" >
            <span class="cursor-pointer" @click="sortByColumn(4)">{{titleObj[4].title}}</span>
             <div v-if="titleObj[4].icon !== ''">
                <descIconSvg v-if="titleObj[4].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
              </div>
          </div>
          <div class="quote_name_col w-56 uppercase  flex items-center" :class="titleObj[5].icon !== '' ? 'text-primary' : ''" >
            <span class="cursor-pointer" @click="sortByColumn(5)">{{titleObj[5].title}}</span>
             <div v-if="titleObj[5].icon !== ''">
                <descIconSvg v-if="titleObj[5].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
              </div>
          </div>
        </div>
        <div v-if="quotationList.length > 0">
          <div v-for="(data, index) in quotationList" :key="index"  class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="goToDetail(data)">
            <div class="quote_name_col w-32">
              <div class="flex items-center">
                <span class="text-gray4 heading-6"># {{data.quotationNumber}}</span>
              </div>
            </div>
            <div class="quote_name_col w-56">
              <div class="">
              <!-- <p class="  text-text1 font-bold">{{}}</p> -->
              <p class="text-gray4 heading-6">{{data.customerFirstName}} {{data.customerLastName}}
                <span v-if="!data.isLead" class="text-primary ">(Customer)</span>
                <span v-if="data.isLead" class="text-success">(Lead)</span>
              </p>
              </div>
            </div>
            <div class="quote_name_col w-48">
              <div class="flex items-center">
              <span class="text-gray4 heading-6">{{data.grandTotal | amountOnlyNumber}}</span>
              </div>
            </div>
            <div class="quote_name_col w-56">
              <div class="flex items-center">
              <span class="text-gray4 heading-6">{{data.expiryDate | formatDataeTime}}</span>
              </div>
            </div>
            <div class="quote_name_col w-32">
              <div class="flex items-center">
              <span :style="`color: ${data.statusColor};`" class=" text-gray4 heading-6">{{data.statusName}}</span>
              </div>
            </div>
            <div class="quote_name_col w-56">
              <div class="flex items-center">
              <span class="text-gray4 heading-6">{{data.quoteDate | formatDataeTime}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="quotationList.length > 0 && mobileView">
        <div v-for="(data, index) in quotationList" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="showJobDetail(data)">
          <div class="">
            <!-- <p class="  text-text1 font-bold">{{}}</p> -->
            <div class="flex justify-between items-center">
              <p class="text-gray4 heading-5">#{{data.quotationNumber}}</p>
              <div class="flex items-center">
                <span :style="`color: ${data.statusColor};`" class=" text-gray4 heading-6">{{data.statusName}}</span>
              </div>
            </div>
            <div class="flex items-center">
              <p class="text-gray4 heading-6 w-24">Quote Date: </p>
              <span class="text-gray4 heading-5">{{data.quoteDate | formatForDatePicker}}</span>
            </div>
            <div class="flex items-center">
              <p class="text-gray4 heading-6 w-24">Due Date: </p>
              <span class="text-gray4 heading-5">{{data.expiryDate | formatForDatePicker}}</span>
            </div>
            <div class="flex items-center">
              <p class="text-gray4 heading-6 w-24">Total: </p>
              <span class="text-gray4 heading-5">{{data.grandTotal | amountOnlyNumber}}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="quotationList.length === 0">
        <NoRecords :alertMessage="`No records found.`" />
      </div>
      <div>
        <Pagination
          :totalDataCount="totalQuoteCount"
          :resetPagination="resetPaginationValues"
          @callApiAsPerPagination="getQuoteDataFromPagination"/>
      </div>
      <div>
      </div>
     </div>
     <div v-if="isValidToken" class="popup_overlay">
        <div class="bg-gray-200 w-full px-2 md:px-0 h-screen flex items-center justify-center">
          <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-20 py-8 rounded-lg shadow-2xl">
              <p class="text-6xl md:text-7xl lg:text-6xl font-bold tracking-wider text-gray-300"><i class="fa-solid fa-triangle-exclamation fa-fade"></i></p>
              <p class="text-gray-500 mt-3 py-2 border-y-2 text-center">Please Check Your Link and Try Again Later</p>
          </div>
        </div>
      </div>
     <div v-if="isExpired">
        <EmailVarified />
     </div>
     <invoice-filter-drawer @applyFilters="applyFilters" :navigationDialog="filterShow"/>
   </div>
</template>
<script>
import MyJobApp from '@/api/MyJobApp.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import ascIconSvg from '@/assets/images/svg/ascIconSvg.vue'
import deboucneMixin from '@/mixins/debounce.js'
import descIconSvg from '@/assets/images/svg/descIconSvg.vue'
import NoRecords from '@/View/components/noRecords.vue'
import Pagination from '@/View/components/pagination.vue'
import InvoiceFilterDrawer from '@/View/invoice/components/InvoiceFilterDrawer.vue';
import EmailVarified from '@/View/endUserNewFlow/components/emailVarified.vue'

export default {
  name: "quotation",
  components: {
    Pagination,
    NoRecords,
    descIconSvg,
    ascIconSvg,
    SearchTextInput,
    InvoiceFilterDrawer,
    EmailVarified
  },
  mixins: [deboucneMixin],
  data() {
    return {
      mobileView: false,
      isValidToken: false,
      isExpired: false,
      sessionToken: '',
      ShowErr: false,
      filterShow: false,
      filterApply: false,
      titleObj: [
        {title: 'Quotation #', icon: '', sortName: 'quotationNumber'},
        {title: 'Customer Name', icon: '', sortName: 'companyName'},
        {title: 'Grand Total', icon: '', sortName: 'grandTotal'},
        {title: 'Expiry Date', icon: 'DESC', sortName: 'expiryDate'},
        {title: 'Status', icon: '', sortName: 'statusName'},
        {title: 'Quotation Date', icon: '', sortName: 'quoteDate'}
      ],
      quotationList: [],
      searchForQuotation: '',
      getQuotationDebounce: null,
      resetPaginationValues: false,
      totalQuoteCount: 0,
      filterObject: {
        start: 0,
        length: 20,
        sortOrder: 'DESC',
        isActive: true,
        sortBy: 'quoteDate',
        customerId: '',
        from: '',
        to: '',
      },
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.resizeWindowHandler()
    document.title = 'EndUser: Quotation'
    this.sessionToken = this.$route.params.customerToken
     this.getQuotationDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.$root.$on('tokenResponse', (isTokenExpired, isTokenInvalid) => {
      if (!isTokenExpired && !isTokenInvalid) {
        this.getQuoteList()
      } else if (isTokenExpired && !isTokenInvalid) {
        this.isExpired = true
        this.isValidToken = false
        this.firstLoading = true
      } else if (!isTokenExpired && isTokenInvalid) {
        this.isExpired = false
        this.isValidToken = true
        this.firstLoading = true
      }
    })
    this.$root.$emit('tokenVarificationApi', this.$route.params.customerToken)
  },
  beforeDestroy() {
    this.$root.$off('tokenResponse')
  },
  methods: {
    applyFilters (data) {
      console.log(data);
      if (data === null) {
        this.filterObject.from = ''
        this.filterObject.to = ''
        this.filterApply = false
        this.getQuoteList()
      } else if (!data) {
        this.filterShow = false
        return
      } else {
        this.filterApply = true
        this.filterObject.from = data.startDate
        this.filterObject.to = data.endDate
        this.getQuoteList()
      }
      this.filterShow = false
    },
    goToDetail (data) {
      this.$router.push({name: 'EndUserQuoteDetail', params: {customerToken: this.sessionToken, quoteToken: data.quotationLinkHash}})
    },
    getQuoteDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getQuoteList(true)
    },
    sortByColumn (index) {
      console.log(index);
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'DESC') {
            this.titleObj[i].icon = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getQuoteList(true)
    },
    searchCall (data) {
      this.searchForQuotation = data
      this.getQuotationDebounce()
    },
    getQuoteList (showlistLoader) {
      if (showlistLoader && this.searchForQuotation !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      if (this.searchForQuotation === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      MyJobApp.GetEndUserQuoteList(
        this.sessionToken,
        this.filterObject.start,
        this.filterObject.length,
        this.searchForQuotation,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        '',
        this.filterObject.from,
        this.filterObject.to,
        response => {
          this.totalQuoteCount = response.Data.count
          this.quotationList = response.Data.tableRow !== null ? response.Data.tableRow : []
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    resizeWindowHandler() {
      if (window.innerWidth < 800) {
        this.mobileView = true
      } else {
        this.mobileView = false
      }
    },
  }
};
</script>
<style scoped>
.quote_name_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>